/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { get } from 'lodash'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { string } from "prop-types"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ imgID, ...attr }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoGofood: file(relativePath: { eq: "GofoodOrder.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoGrabfood: file(relativePath: { eq: "GrabfoodOrder.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kartuNama: file(relativePath: { eq: "kartuNamaBPK.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BPK: file(relativePath: { eq: "BPK.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerBPK1: file(relativePath: { eq: "BannerBPK1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerBPK2: file(relativePath: { eq: "BannerBPK2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerBPK3: file(relativePath: { eq: "BannerBPK3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      MenuBPK: file(relativePath: { eq: "menuGallery/BPK.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      MenuKidu: file(relativePath: { eq: "menuGallery/KiduKidu.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, quality: 20) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      MenuSoup: file(relativePath: { eq: "menuGallery/SoupBPK.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, quality: 20) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      MenuIkan: file(relativePath: { eq: "menuGallery/IkanMasArsik.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, quality: 20) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      MenuSaksang: file(relativePath: { eq: "menuGallery/Saksang.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, quality: 20) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      MenuB2Goreng: file(relativePath: { eq: "menuGallery/MenuB2Goreng.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={get(data, `${imgID}.childImageSharp.fluid`, 'bg01')}
      objectFit="cover"
      // objectPosition="50% 50%"
      {...attr}
    />
  );
}

Image.propTypes = {
  imgID: string,
};

Image.defaultProps = {
  imgID: 'bg01',
};

export default Image
